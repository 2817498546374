import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";

const AuthContextProvide = createContext();

const useAuth = () => useContext(AuthContextProvide);

const AuthContext = ({ children }) => {
  const [maxWidth, setMaxWidth] = useState("500px");
  const [isMobile, setIsMobile] = useState(false);

  const [singleProduct, setSingleProduct] = useState({});
  const [cartProducts, setCartProducts] = useState([]);
  const [whiteListProducts, setWhiteListProducts] = useState([]);
  const [addNewItem, setAddNewItem] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMRP, setTotalMRP] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalExtraDiscount, setTotalExtraDiscount] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [logo, setLogo] = useState("");
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState({});
  const [hideAddress, setHideAddress] = useState(false);
  const [category, setCategory] = useState({});
  const [storedTime, setStoredTime] = useState(null);
  const [themColor, setThemColor] = useState(process.env.REACT_APP_THEAM_COLOR);
  const [isPaymentPageLoading, setIsPaymentPageLoading] = useState(false);
  const [selectCategory, setSelectcategory] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 512);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleAddNewItem = (add) => {
    setAddNewItem(add);
  };
  const handleSetCartProducts = (data) => {
    setCartProducts(data);
    localStorage.setItem("cartProducts", JSON.stringify(data));
  };
  const handleSetWhiteListProducts = (item) => {
    if (whiteListProducts?.find((o) => o.id === item.id)) {
      const selectedItem = whiteListProducts.filter((o) => o.id !== item.id);
      setWhiteListProducts(selectedItem);
    } else {
      setWhiteListProducts((prevState) => [...prevState, item]);
    }
  };

  // const handleSliderData = () => {
  //   let url = `${process.env.REACT_APP_API_URL}/api/slider-image/get`;
  //   axios
  //     .get(url)
  //     .then(function (response) {
  //       setSliderImages(response?.data?.data?.slideImages || []);
  //       setLogo(response?.data?.data?.logo || []);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log("---- error", error);
  //     });
  // };

  // useEffect(() => {
  //   handleSliderData();
  // }, []);

  useEffect(() => {
    if (cartProducts.length > 0) {
      localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
      // setStoredTime(new Date().getTime());
    }
  }, [cartProducts]);

  useEffect(() => {
    if (selectedProduct.length > 0) {
      localStorage.setItem("slectedData", JSON.stringify(selectedProduct));
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (address.mobile) {
      localStorage.setItem("address", JSON.stringify(address));
    }
  }, [address]);

  useEffect(() => {
    const storageData = localStorage.getItem("cartProducts");
    const slectedData = localStorage.getItem("slectedData");
    const addressData = localStorage.getItem("address");
    if (storageData) {
      setCartProducts(JSON.parse(storageData));
    }
    if (slectedData) {
      setSelectedProduct(JSON.parse(slectedData));
    }
    if (addressData) {
      setAddress(JSON.parse(addressData));
    }
  }, []);

  const handleSelectProduct = (id) => {
    if (selectedProduct?.find((o) => o.id === id)) {
      const selectedItem = selectedProduct.filter((o) => o.id !== id);
      setSelectedProduct(selectedItem);
      localStorage.setItem("slectedData", JSON.stringify(selectedItem));
    } else {
      const item = cartProducts.find((o) => o.id === id);
      setSelectedProduct((prevState) => {
        const seletedData = [...prevState, item];
        localStorage.setItem("slectedData", JSON.stringify(seletedData));
        return seletedData;
      });
    }
  };

  const calcExtraDiscount = (
    arr,
    pickIndex,
    remainingQuantity,
    totalExtraDiscount
  ) => {
    const bb = arr[pickIndex];
    if (bb?.quantity > remainingQuantity) {
      totalExtraDiscount +=
        (parseInt(bb?.variants?.[0]?.price) || 0) * remainingQuantity;
      return totalExtraDiscount;
    } else {
      totalExtraDiscount += parseInt(bb?.variants?.[0]?.price) * bb?.quantity;
      remainingQuantity = remainingQuantity - bb?.quantity;
      if (remainingQuantity > 0) {
        return calcExtraDiscount(
          arr,
          pickIndex - 1,
          remainingQuantity,
          totalExtraDiscount
        );
      } else {
        return totalExtraDiscount;
      }
    }
  };

  const handlePriceData = (products) => {
    let totalPrice = 0;
    let totalMRP = 0;
    let totalDiscount = 0;
    let totalExtraDiscount = 0;

    let Allquantity = products.reduce((acc, cur) => acc + cur?.quantity, 0);
    const qua = Array.from({ length: Allquantity }, (value, index) => index);
    const originalArray = qua;
    const groupSize = 3;
    const subArrays = [];

    for (let i = 0; i < originalArray.length; i += groupSize) {
      const group = originalArray.slice(i, i + groupSize);
      if (group.length === groupSize) {
        subArrays.push(group);
      }
    }
    const countSubArraysLength3 = subArrays.length;

    const sortArry = cartProducts.sort(
      (a, b) => b.variants?.[0]?.price - a.variants?.[0]?.price
    );

    totalExtraDiscount = calcExtraDiscount(
      sortArry,
      sortArry.length - 1,
      countSubArraysLength3,
      totalExtraDiscount
    );
    products.forEach((product) => {
      const { price = 0, compare_at_price } = product?.variants?.[0];
      const { quantity } = product;
      let comparePrice =
        compare_at_price && parseInt(compare_at_price) > 0
          ? compare_at_price
          : price * 3;
      // let comparePrice=(compare_at_price||parseInt(compare_at_price)>0)?parseInt(compare_at_price):parseInt(price)*3
      // Apply buy 2 get 1 free logic
      const totalMrpWithoutDiscount = parseInt(comparePrice) * quantity;
      const totalPriceDiscount = parseInt(price) * quantity;

      // Update totals
      totalMRP += totalMrpWithoutDiscount;
      totalDiscount += totalMrpWithoutDiscount - totalPriceDiscount;
      totalPrice += totalPriceDiscount;
    });
    totalPrice = totalPrice - totalExtraDiscount;

    setTotalPrice(totalPrice);
    setTotalMRP(totalMRP);
    setTotalDiscount(totalDiscount);
    setTotalExtraDiscount(totalExtraDiscount);
  };

  useEffect(() => {
    handlePriceData(selectedProduct);
  }, [selectedProduct]);

  // Function to clear local storage
  async function clearLocalStorage() {
    await localStorage.removeItem("cartProducts");
    await localStorage.removeItem("slectedData");
    await localStorage.removeItem("address");
  }
  useEffect(() => {
    // Check and clear local storage after 15 minutes
    function checkAndClearLocalStorage() {
      let currentTime = new Date().getTime();
      let elapsedTime = currentTime - storedTime;

      // If 15 minutes have passed, clear local storage
      if (elapsedTime >= 15 * 60 * 1000) {
        // 15 minutes in milliseconds
        clearLocalStorage();
      }

      // Set the timeout for the next check
      setTimeout(checkAndClearLocalStorage, 60 * 1000); // Check every minute
    }
    checkAndClearLocalStorage();
  }, [storedTime]);

  useEffect(() => {
    setThemColor(process.env.REACT_APP_THEAM_COLOR);
  }, [process.env.REACT_APP_THEAM_COLOR]);

  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(window.innerWidth > 512 ? "100%" : "500px");
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AuthContextProvide.Provider
      value={{
        isMobile,
        cartProducts,
        handleSetCartProducts,
        addNewItem,
        handleAddNewItem,
        totalPrice,
        totalDiscount,
        totalMRP,
        selectedProduct,
        handleSelectProduct,
        setSelectedProduct,
        step,
        setStep,
        handleSetWhiteListProducts,
        whiteListProducts,
        singleProduct,
        setSingleProduct,
        address,
        setAddress,
        hideAddress,
        setHideAddress,
        sliderImages,
        logo,
        category,
        setCategory,
        themColor,
        totalExtraDiscount,
        isPaymentPageLoading,
        setIsPaymentPageLoading,
        setSelectcategory,
        selectCategory,
        clearLocalStorage,
      }}
    >
      <Container
        className="p-0"
        style={{
          margin: "auto",
          maxWidth,
          position: "relative",
          "--them-color": themColor,
        }}
      >
        {children}
      </Container>
    </AuthContextProvide.Provider>
  );
};

export { useAuth, AuthContext };
