import { SHA256 } from "crypto-js";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const ThankYou = () => {
  const navigate = useNavigate();
  const {
    handleSetCartProducts,
    clearLocalStorage,
    setAddress,
    setHideAddress,
  } = useAuth();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const txnId = queryParams.get("txnId");
  const userData = JSON.parse(localStorage.getItem("address"));
  const encodeData = (data) => {
    return SHA256(data?.toString()?.trim().toLowerCase());
  };
  const address = `${encodeData(userData?.address1 || "")}, ${encodeData(
    userData?.address2 || ""
  )}`;
  setTimeout(() => {
    localStorage.clear();
  }, 3 * 60 * 1000);

  useEffect(() => {
    if (process.env.REACT_APP_COD_PAYMENT === "true") {
      clearLocalStorage();
      setAddress({});
      setHideAddress(false);
    }
    handleSetCartProducts([]);
  }, []);

  const totalAmount = localStorage.getItem("totalPrice");
  return (
    <Container>
      <div>
        {process.env.REACT_APP_FBPIXEL && txnId !== null && (
          <Helmet>
            <script>
              {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${process.env.REACT_APP_FBPIXEL}');
fbq('track', 'Purchase', {
    value: '${totalAmount}',
    currency: 'INR',
    contents: [
        {
            id: '${txnId}',
        }
    ],
    content_type: 'product'
});
fbq('track', 'PageView');
`}
            </script>
          </Helmet>
        )}

        {process.env.REACT_APP_AW && (
          <Helmet>
            <script>
              {`
            gtag('event', 'conversion', {
      'send_to': '${process.env.REACT_APP_AW}/${
                process.env.REACT_APP_PURCHASETAGGOOGLE || ""
              }',
      'value': ${totalAmount},
      'currency': 'INR',
      'transaction_id': '${txnId}',
       'user_data':{
       'phone_number':  '${encodeData(userData?.mobile)}',
           'first_name': '${encodeData(userData?.fullname)}',
          'last_name':'${encodeData(userData?.fullname)}',
          'home_address': {
          'street': '${address}',
          'city':'${encodeData(userData?.city)}',
          'region': '${encodeData(userData?.state)}',
          'postal_code': '${encodeData(userData?.pincode)}',
          'country': 'IN'
       }
          }
  });
          `}
            </script>
          </Helmet>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px 20px",
          }}
        >
          <h4>YOUR ORDER HAS BEEN RECEIVED</h4>
          <h5>Confirmation of Order Receipt and Payment Processing</h5>
          <p style={{ color: "red" }}>
            Please note that if your payment is unsuccessful, your order will be
            automatically canceled. Kindly ensure that you do not close any UPI
            app until the payment process is completed.
          </p>
          <h6>
            Upon successful processing, you will receive an order confirmation
            email containing detailed information about your order and a link to
            track its progress.
          </h6>
          <h6>
            {`Thank you for choosing ${
              window.location.hostname || ""
            }. If you have any questions or concerns, feel free to reach out to us.`}
          </h6>
          <p>
            <strong>Your transaction id is:</strong>
            &nbsp;{txnId}
          </p>
          <p>
            <strong>Your Amount is:</strong>
            &nbsp;{totalAmount}
          </p>
        </div>
        <Button
          variant="dark"
          className="btn my-3 primary d-flex m-auto justify-content-center align-items-center ripple animated"
          style={{
            padding: "10px 20px",
            background: "var(--them-color)",
            borderColor: "var(--them-color)",
          }}
          onClick={(e) => {
            e?.target?.classList?.add("bounceIn");
            navigate(`/`);
            setTimeout(() => {
              if (e?.target?.classList?.contains("bounceIn"))
                e?.target?.classList?.remove("bounceIn");
            }, 1000);
          }}
        >
          Go to Home
        </Button>
      </div>
    </Container>
  );
};
export default ThankYou;
