import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

const ReturnPolicy = () => {
  return (
    <div>
      <Container id="main" role="main">
        {/* <div className="shopify-policy__container">
          <div className="shopify-policy__title">
            <div className="container mx-auto px-4 py-8">
              <h1 className="text-2xl font-bold mb-4">Return Policy</h1>
              <p className="mb-4">
                We have a 5-day return policy, which means you have 5 days after
                receiving your item to request a return. Once the return product
                is received it will be inspected and the return will be approved
                within 2 days
              </p>
              
              <p className="mb-4">
                To start a return, you can contact us at:{" "}
                <strong>support@{window.location.href}</strong>
              </p>
              
              <p>
                We will notify you once we’ve received and inspected your
                return, and let you know if the refund was approved or not. If
                approved, you’ll be automatically refunded on your original
                payment method within 10 business days. Please remember it can
                take some time for your bank or credit card company to process
                and post the refund too.
              </p>
            </div>

            <Form
              method="post"
              action="/contact#footer-newsletter"
              id="footer-newsletter"
              acceptCharset="UTF-8"
              className="my-3"
            >
              <input type="hidden" name="form_type" value="customer" />
              <input type="hidden" name="utf8" value="✓" />
              <input type="hidden" name="contact[tags]" value="newsletter" />
              <Form.Control
                size="lg"
                type="email"
                placeholder="Enter email"
                className="mb-3"
              />
              <Button
                type="submit"
                variant="dark"
                className="mt-3"
                style={{
                  background: "var(--them-color)",
                  borderColor: "var(--them-color)",
                }}
              >
                Subscribe
              </Button>
            </Form>
          </div>
        </div> */}
        <div>
          {/* <h1>Return & Refund Policy</h1> */}

          <div>
            <h3 className="mt-4">Returns</h3>
            <p className="fs-5 m-0">
              We have a 5-day return policy, which means you have 5 days after
              receiving your item to request a return.
            </p>
            <p className="fs-5">
              Once the return product is received it will be inspected and the
              return will be approved within 2 days.
            </p>
          </div>

          <div>
            <h3 className="mt-4">Refunds</h3>
            <p className="fs-5 m-0">
              We will notify you once we’ve received and inspected your return,
              and let you know if the refund was approved or not. If approved,
              you’ll be automatically refunded on your original payment method
              within 10 business days. Please remember it can take some time for
              your bank or credit card company to process and post the refund
              too.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReturnPolicy;
