import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

const TermsAndConditions = () => {
  return (
    <Container className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Terms & Conditions</h1>

      <div>
        <h3>
          <span>1</span> Introduction
        </h3>
        <p className="fs-5">
          Welcome to Touch Cloth. By accessing and using our website &nbsp;
          {window.location.hostname} , you agree to comply with and be bound by
          the following Terms and Conditions. If you do not agree with these
          terms, please refrain from using our website and services.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>2</span> Definitions
        </h3>
        <p className="fs-5 m-0">
          "Company," "we," "our," or "us" refers to Touch Cloth.
        </p>
        <p className="fs-5 m-0">
          "User," "you," or "your" refers to any individual or entity accessing
          our website.
        </p>
        <p className="fs-5 m-0">
          "Products" refer to the clothing and related items sold on our
          website.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>3</span> Use of the Website
        </h3>
        <p className="fs-5 m-0">
          You must be at least 18 years old or access the website under the
          supervision of a legal guardian.
        </p>

        <p className="fs-5 m-0">
          You agree to use the website for lawful purposes only.
        </p>

        <p className="fs-5 m-0">
          We reserve the right to refuse service to anyone at our discretion.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>4</span> Account Registration
        </h3>
        <p className="fs-5 m-0">
          Users may be required to create an account to place orders.
        </p>
        <p className="fs-5 m-0">
          You are responsible for maintaining the confidentiality of your
          account information.
        </p>
        <p className="fs-5 m-0">
          Any unauthorized use of your account should be reported immediately.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>5</span> Orders and Payment
        </h3>
        <p className="fs-5 m-0">
          All orders placed are subject to availability and acceptance by us.
        </p>
        <p className="fs-5 m-0">
          Prices are listed in INR and include applicable taxes unless stated
          otherwise.
        </p>

        <p className="fs-5 m-0">
          Payments can be made via the available payment methods on our website.
        </p>

        <p className="fs-5 m-0">
          We reserve the right to cancel or refuse any order at our discretion.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>6</span> Shipping and Delivery
        </h3>
        <p className="fs-5 m-0">
          Orders are processed within the stipulated time mentioned at checkout.
        </p>
        <p className="fs-5 m-0">
          Delivery times may vary based on location, shipping carrier, and
          unforeseen circumstances.
        </p>
        <p className="fs-5 m-0">
          We are not responsible for delays caused by third-party courier
          services.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>7</span> Returns and Refunds
        </h3>
        <p className="fs-5 m-0">
          Returns are accepted within a specified period mentioned in our Return
          Policy.
        </p>
        <p className="fs-5 m-0">
          Products must be unused, in their original packaging, and with all
          tags intact.
        </p>
        <p className="fs-5 m-0">
          Refunds will be processed as per the Refund Policy mentioned on our
          website.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>8</span> Cancellations and Modifications
        </h3>
        <p className="fs-5 m-0">
          Orders can only be canceled or modified before they are dispatched.
        </p>
        <p className="fs-5 m-0">
          Cancellation requests after dispatch may not be entertained.
        </p>
        <p className="fs-5 m-0">
          Refunds for canceled orders will be processed within 7 working days.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>9</span> Intellectual Property
        </h3>
        <p className="fs-5 m-0">
          All content on the website, including text, images, logos, and
          graphics, is owned by Touch Cloth.
        </p>
        <p className="fs-5 m-0">
          Unauthorized reproduction, distribution, or use of any content is
          strictly prohibited.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>10</span> Privacy Policy
        </h3>
        <p className="fs-5 m-0">
          Your personal information is collected, stored, and used in accordance
          with our Privacy Policy.
        </p>
        <p className="fs-5 m-0">
          We do not sell or share your personal data with third parties without
          consent.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>11</span> Limitation of Liability
        </h3>
        <p className="fs-5 m-0">
          We are not liable for any indirect, incidental, or consequential
          damages arising from the use of our products or website.
        </p>
        <p className="fs-5 m-0">
          In no event shall our liability exceed the total amount paid by the
          user for the order.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>12</span> Third-Party Links
        </h3>
        <p className="fs-5 m-0">
          Our website may contain links to third-party websites. We are not
          responsible for their content or policies.
        </p>
        <p className="fs-5 m-0">
          Users should review third-party terms before engaging with such
          websites.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>13</span> Indemnification
        </h3>
        <p className="fs-5 m-0">
          You agree to indemnify and hold us harmless from any claims, damages,
          or expenses arising from your violation of these terms.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>14</span> Governing Law and Jurisdiction
        </h3>
        <p className="fs-5 m-0">
          These Terms and Conditions are governed by the laws of India.
        </p>

        <p className="fs-5 m-0">
          Any disputes arising shall be subject to the exclusive jurisdiction of
          the courts in Bhavnagar, Gujarat.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>15</span> Amendments to Terms
        </h3>
        <p className="fs-5 m-0">
          We reserve the right to update or modify these terms at any time.
        </p>

        <p className="fs-5 m-0">
          Continued use of the website after changes constitute acceptance of
          the new terms.
        </p>
      </div>

      <div className="my-3">
        <h3>
          <span>16</span> Contact Information
        </h3>
        <p className="fs-5 m-0">
          For any questions or concerns regarding these Terms and Conditions,
          you may contact us at:
        </p>

        <p className="my-4">
          <strong>Name:</strong> {process.env.REACT_APP_FAM}
          <br />
          <strong>Designation:</strong> Proprietor
          <br />
          <strong>Address:</strong> {process.env.REACT_APP_ADDRESS}
          <br />
          <strong>Contact:</strong> support@{window.location.hostname}, Phone:{" "}
          {process.env.REACT_APP_MO}
          <br />
          <strong>Hours:</strong> Monday - Friday (9:00 AM - 6:00 PM)
        </p>
        <p>
          By using our website, you acknowledge that you have read, understood,
          and agree to be bound by these Terms and Conditions.
        </p>
      </div>

      <div className="text-center mt-6">
        <h3
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#727272",
            textAlign: "center",
          }}
        >
          Get Our Newsletter
        </h3>
        <div className="Footer__Content Rte">
          Subscribe to receive updates, access to exclusive deals, and more.
        </div>
        <Form
          method="post"
          action="/contact#footer-newsletter"
          id="footer-newsletter"
          acceptCharset="UTF-8"
          className="my-3"
        >
          <input type="hidden" name="form_type" value="customer" />
          <input type="hidden" name="utf8" value="✓" />
          <input type="hidden" name="contact[tags]" value="newsletter" />
          <Form.Control
            size="lg"
            type="email"
            placeholder="Enter email"
            className="mb-3"
          />
          <Button
            type="submit"
            variant="dark"
            className="mt-3"
            style={{
              background: "var(--them-color)",
              borderColor: "var(--them-color)",
            }}
          >
            Subscribe
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
