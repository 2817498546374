import axios from "axios";
import "keen-slider/keen-slider.min.css";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Countdown from "react-countdown";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import animaionImageHOme from "../../assets/2f53o.gif";
import { useAuth } from "../../contexts/AuthContext";
import OfferCountdown from "../Header/OfferCountdown";
import ProductCard from "../ProductCard";
import SkeletonLoader from "../SkeletonLoader";
import Spinner from "../Spinner";
import "./index.css";

const Home = () => {
  const { isMobile } = useAuth();
  const navigate = useNavigate();
  const [categoryArray, setCategoryArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);
  const [collectionHandle, setCollectionHandle] = useState([]);
  const ref = useRef(null);
  let location = useLocation();

  const sliderImages = JSON.parse(process.env.REACT_APP_SLIDER) || [];

  useEffect(() => {
    if (ref?.current) {
      if (["STOPPED", "COMPLETED"].includes(ref?.current?.state?.status)) {
        ref?.current?.start();
      }
    }
  }, [location, ref]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SHOPIFY_API_URL}/collections.json`)
      .then(function (response) {
        // handle success
        if (
          response?.data?.collections?.length > 0 &&
          response?.status === 200
        ) {
          setCategoryArray(response?.data?.collections?.slice(0, 8));
        }
      })
      .catch(function (error) {
        // handle error
        console.log("---- error", error);
        setCategoryArray([]);
      });
  }, []);

  const handleProductData = async () => {
    if (categoryArray.length === 0) return; // Ensure categories are available
    setIsLoader(true);

    const handleList = categoryArray.map((val) => val.handle);
    setCollectionHandle(handleList);

    try {
      const productPromises = handleList.map(
        (handle) =>
          axios
            .get(
              `${process.env.REACT_APP_SHOPIFY_API_URL}/collections/${handle}/products.json`
            )
            .then((response) =>
              response?.data?.products
                ? response.data.products.slice(0, 20)
                : []
            )
            .catch(() => []) // Return empty array on error
      );

      const products = await Promise.all(productPromises); // Wait for all product fetches
      setProductsArray(products); // Flatten and update state
    } catch (error) {
      console.error("Error fetching products:", error);
      setProductsArray([]);
    } finally {
      setIsLoader(false); // Hide loader
    }
  };

  useEffect(() => {
    setIsLoader(true);
  }, []);

  useEffect(() => {
    handleProductData();
  }, [categoryArray]);

  const settingSlick = {
    dots: false,
    infinite: sliderImages.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="category_block">
        <Container className={isMobile ? "px-0" : ""}>
          {isMobile ? (
            <div>
              {categoryArray?.length > 1 && (
                <Row
                  className={
                    "d-flex flex-row flex-wrap overflow-x-auto overflow-y-hidden category-box mb-2 gy-2"
                  }
                >
                  {categoryArray?.map(
                    (item, index) =>
                      item?.image?.src && (
                        <Col xs={3} lg={2} md={3} key={`${index}-homeimg`}>
                          <Image
                            onClick={() => {
                              navigate(`/category/${item?.handle}`);
                            }}
                            src={item?.image?.src || ""}
                            square="true"
                            style={{
                              width: "100%",
                              height: "100%",
                              // border: "1px solid black",
                              maxHeight: "100px",
                              maxWidth: "100px",
                            }}
                          />
                        </Col>
                      )
                  )}
                </Row>
              )}
            </div>
          ) : (
            <div></div>
          )}

          {isMobile ? (
            <Row>
              <div>
                <img src={animaionImageHOme} className="w-100 mb-2" />
              </div>
            </Row>
          ) : (
            <div></div>
          )}

          <Row>
            {sliderImages.length > 0 && (
              <Col
                md={12}
                lg={12}
                xs={12}
                className="position-relative sliders_main"
              >
                <Slider {...settingSlick}>
                  {sliderImages?.map((image, index) => (
                    <div key={index} className="p-2">
                      <img
                        src={image}
                        alt={`Knee ${index + 1}`}
                        className="w-full rounded-lg shadow-lg"
                        style={{
                          width: "100%",
                          height: isMobile ? "auto" : "500px",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  ))}
                </Slider>

                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "12px",
                    right: "12px",
                  }}
                ></div>
              </Col>
            )}
          </Row>

          {isLoader ? (
            <Spinner />
          ) : (
            <div
              className="menu mt-2 "
              style={{ backgroundColor: process.env.REACT_APP_THEAM_COLOR }}
            >
              <marquee
                width="100%"
                direction="left"
                height="30px"
                fontWeight="700"
                style={{ color: "white" }}
              >
                <span>Buy 2 Get 1 Free (Add 3 item to cart)</span>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <span>Buy 2 Get 1 Free (Add 3 item to cart)</span>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <span>Buy 2 Get 1 Free (Add 3 item to cart)</span>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <span>Buy 2 Get 1 Free (Add 3 item to cart)</span>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <span>Buy 2 Get 1 Free (Add 3 item to cart)</span>
              </marquee>
            </div>
          )}
          {/* <Image src="https://images.meesho.com/images/widgets/OY6J5/xwgyl_800.webp" style={{ width: "100%" }} /> */}

          {isMobile ? (
            <div className="main-time">
              <div className="inner-time">
                <div className="dod-div">
                  <div className="dod-label">
                    Hurry Up !! <span className="big-sale-text">Big Sale</span>
                  </div>

                  <div
                    className="container p-3"
                    style={{ textAlign: "center", border: "none" }}
                  >
                    <span>
                      <Countdown
                        date={Date.now() + parseInt(500000)}
                        ref={ref}
                        renderer={(e) => <OfferCountdown />}
                        intervalDelay={1000}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {isLoader ? (
            <Row xs={2} md={2} className="g-0 mt-2">
              <Col>
                <SkeletonLoader />
              </Col>
              <Col>
                <SkeletonLoader />
              </Col>
              <Col>
                <SkeletonLoader />
              </Col>
              <Col>
                <SkeletonLoader />
              </Col>
            </Row>
          ) : (
            <div>
              {productsArray?.map((item, i) => (
                <>
                  {item?.length > 0 && (
                    <div key={`${collectionHandle[i] - i}`}>
                      <h4 className="card-title text-center fw-bold my-3">{`${collectionHandle[
                        i
                      ]
                        ?.slice(0, 1)
                        .toUpperCase()
                        ?.concat(
                          collectionHandle[i]?.slice(1).toLowerCase()
                        )} Collection`}</h4>
                      <Row xs={2} lg={5} md={2} className="g-0 mt-2">
                        {item?.map((product, index) => (
                          <ProductCard
                            key={`${collectionHandle[i]}-${index}`} // Use a combination of collectionHandle and index as the key
                            item={product}
                            index={index}
                            handler={collectionHandle[i]}
                          />
                        ))}
                      </Row>
                      <Button
                        className="btn my-3 d-flex justify-content-center align-items-center ripple animated"
                        style={{
                          fontWeight: 600,
                          fontSize: "18px",
                          margin: "auto",
                          borderWidth: "2px",
                          padding: "10px 20px",
                          borderColor: "var(--them-color)",
                          color: "var(--them-color)",
                          background: "#ffff",
                        }}
                        onClick={(e) => {
                          e?.target?.classList?.add("bounceIn");
                          navigate(`/category/${collectionHandle[i]}`);
                          setTimeout(() => {
                            if (e?.target?.classList?.contains("bounceIn"))
                              e?.target?.classList?.remove("bounceIn");
                          }, 1000);
                        }}
                        disabled={end == 60}
                      >
                        View More
                      </Button>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Home;
