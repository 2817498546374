import axios from "axios";

// ✅ Create Order (Initiate Payment)
export const createOrder = async (amount, domainname) => {
  try {
    const data = {
      amount,
      domainname,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/phonepay/sdk/create-order`,
      data
    );

    return response.data.checkoutUrl;
  } catch (error) {
    console.error(
      "Error creating order:",
      error.response?.data || error.message
    );
    throw error;
  }
};
