import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useRazorpay } from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { createOrder } from "../../services/phonePeService";
import "./index.css";

const Payment = () => {
  const {
    selectedProduct,
    totalPrice,
    totalDiscount,
    totalMRP,
    totalExtraDiscount,
    isPaymentPageLoading,
  } = useAuth();

  const navigate = useNavigate();
  const { Razorpay } = useRazorpay();
  const userDetails = JSON.parse(localStorage.getItem("address"));
  const [loading, setLoading] = useState(false);

  const handlePhonePayPayment = async () => {
    // const data = {
    //   name: userDetails?.fullname,
    //   mobileNumber: userDetails?.mobile,
    //   amount: totalPrice,
    //   merchantkey: process.env.REACT_APP_PHONEPE_MKEYS,
    //   merchantid: process.env.REACT_APP_PHONEPE_MIDS,
    //   domainname: window.location.hostname,
    //   noderedirectUrl: process.env.REACT_APP_PHONEPE_NODE_URL,
    // };

    // try {
    //   setLoading(true);
    //   const res = await axios.post(
    //     `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/phonepay/create-order`,
    //     data
    //   );

    //   if (res.data?.data?.instrumentResponse?.redirectInfo?.url) {
    //     window.open(res.data.data.instrumentResponse.redirectInfo.url, "_self");
    //   }
    // } catch (error) {
    //   console.error("Error in payment:", error);
    // } finally {
    //   setLoading(false);
    // }
    const domainname = window.location.hostname;
    const amount = totalPrice;

    try {
      setLoading(true);
      const checkoutUrl = await createOrder(amount, domainname);
      window.location.href = checkoutUrl;
    } catch (error) {
      alert("Payment initiation failed. Try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleRazorpayPayment = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/razorpay/create-order`,
        {
          amount: totalPrice,
          currency: "INR",
          razorpaykeyid: process.env.REACT_APP_RAZORPAY_KEY_ID,
          razorpaykeysecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
        }
      );

      let paymentStatus = false; // Track if payment was completed
      setLoading(false);
      const options = {
        key: data.razorpaykeyid,
        amount: data.amount,
        currency: data.currency,
        name: window.location.hostname,
        description: "",
        image: process.env.REACT_APP_LOGO,
        order_id: data.id,
        handler: async (response) => {
          setLoading(true);
          try {
            const { data: verifyResponse } = await axios.post(
              `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/razorpay/verify-payment`,
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                razorpay_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
              }
            );

            if (verifyResponse.success) {
              paymentStatus = true; // Mark as success
              setLoading(false);
              navigate(
                `/thankyou?txnId=${response.razorpay_order_id.replace(
                  "order_",
                  ""
                )}`
              );
            } else {
              setLoading(false);
              alert("Payment verification failed!");
              console.log("Payment verification failed, redirecting to cart.");
              window.location.href = `https://${window.location.hostname}/cart`;
            }
          } catch (error) {
            setLoading(false);
            console.error("Error verifying payment:", error);
            window.location.href = `https://${window.location.hostname}/cart`;
          }
        },
        prefill: {
          name: window.location.hostname,
          email: `support@${window.location.hostname}.com`,
          contact: userDetails.mobile,
        },
        notes: {
          address: userDetails.address1,
        },
        theme: {
          color: process.env.REACT_APP_THEAM_COLOR,
        },
        modal: {
          escape: false,
          ondismiss: function () {
            setTimeout(() => {
              if (!paymentStatus) {
                console.log("User closed the payment popup or navigated back.");
                window.location.href = `https://${window.location.hostname}/cart`;
              }
            }, 500); // Small delay to avoid conflicts
          },
        },
      };

      const razorpay = new Razorpay(options);
      razorpay.open();
    } catch (err) {
      console.error("Error initiating payment:", err);
    }
  };

  // CashFree Integration
  let cashfree;

  var initializeSDK = async function () {
    cashfree = await load({
      mode: "production",
    });
  };

  initializeSDK();

  const getSessionId = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/cashfree/create-order`,
        {
          cashfreeappid: process.env.REACT_APP_CASHFREE_APP_ID,
          cashfreesecretkey: process.env.REACT_APP_CASHFREE_SECRET_KEY,
          domainname: window.location.hostname,
          amount: totalPrice,
          customerphone: `+91${userDetails?.mobile}`,
          customername: userDetails?.fullname,
        }
      );
      if (response && response.data.payment_session_id) {
        localStorage.setItem("cashfree_order_id", response.data.order_id);
        return response.data.payment_session_id;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCashFreePayPayment = async () => {
    try {
      let sessionId = await getSessionId();

      if (!sessionId) {
        console.error("Payment session ID or Cashfree SDK not initialized");
        return;
      }
      cashfree.checkout({
        paymentSessionId: sessionId,
        redirectTarget: "_self",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleIndianPayPayment = async () => {
    const data = {
      name: userDetails?.fullname,
      mobile: `+91${userDetails?.mobile}`,
      amount: totalPrice,
      domainname: window.location.hostname,
      email: "",
      remark: "",
      type: "",
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/pay/create-order`,
        data
      );
      if (response.data?.payment_link) {
        window.open(response.data?.payment_link, "_self");
      }
    } catch (error) {
      console.error("Error in payment:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const randomTxnId = Math.floor(10000000 + Math.random() * 90000000);

  const payNow = async () => {
    const paymentType = process.env.REACT_APP_PAYMENT_TYPE;
    if (paymentType === "razorpay") {
      handleRazorpayPayment();
    } else if (paymentType === "phonepe") {
      handlePhonePayPayment();
    } else if (paymentType === "cashfree") {
      handleCashFreePayPayment();
    } else if (paymentType === "indianpay") {
      handleIndianPayPayment();
    } else if (process.env.REACT_APP_COD_PAYMENT === "true") {
      navigate(`/thankyou?txnId=${randomTxnId}`);
    } else {
      setLoading(true);
    }
  };

  localStorage.setItem("totalPrice", totalPrice);

  return isPaymentPageLoading ? (
    <Container
      className="p-0 pt-3 pb-3 flex-column position-relative d-flex justify-content-center align-items-center"
      style={{ background: "#f2f2f3", height: "250px" }}
    >
      <div>Please Wait...</div>
      <Spinner />
    </Container>
  ) : (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(255,255,255,0.8)",
            zIndex: 9999999999,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </div>
      )}
      <Container
        className="p-0 pt-3 pb-3 position-relative d-flex flex-column justify-content-between"
        style={{ background: "#f2f2f3" }}
      >
        <div>
          <div className="mt-3">
            {selectedProduct?.length && (
              <div className="bg-white px-4 py-4">
                <h6
                  id="product_details"
                  className="card-title text-start fw-bold border-bottom pb-2"
                >{`PRICE DETAILS (${
                  selectedProduct?.length === 1
                    ? "1 Item"
                    : `${selectedProduct?.length} Items`
                })`}</h6>
                <div className="mt-3">
                  <div className="d-flex flex-row justify-content-between align-items-center ">
                    <span>Total MRP</span>
                    <span className="ms-2">
                      <span>
                        <span className="">₹</span>
                        {totalMRP}
                      </span>
                    </span>
                  </div>
                  {totalDiscount ? (
                    <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                      <span>Discount on MRP</span>
                      <span className="ms-2 text-success">
                        <span>
                          - <span className="">₹</span>
                          {totalDiscount}
                        </span>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {totalExtraDiscount ? (
                    <>
                      <div className="d-flex flex-row justify-content-between align-items-center mt-2 border-top pt-2">
                        <span>Total Price</span>
                        <span className="ms-2">
                          <span>
                            <span className="">₹</span>
                            {totalMRP - totalDiscount}
                          </span>
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center mt-2 ">
                        <span>Coupon Applied (Buy 2 Get 1 free)</span>
                        <span className="ms-2 text-success">
                          <span>
                            -<span className="">₹</span>
                            {totalExtraDiscount}
                          </span>
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="d-flex flex-row justify-content-between align-items-center mt-2 fw-bold border-top pt-3">
                    <span>Total Amount</span>
                    <span className="ms-2">
                      <span>
                        <span className="">₹</span>
                        {totalPrice}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="position-sticky bottom-0 pb-3 bg-white px-4 mt-3 py-4 d-flex align-content-center justify-content-between"
          id="payment_bottom_block"
        >
          <div
            style={{
              display: "inline-block",
              fontSize: "16px",
              fontWeight: 700,
              color: "#282c3f",
              textAlign: "start",
            }}
          >
            <h6
              className="mb-0"
              style={{ fontWeight: "bold", fontSize: "22px" }}
            >
              ₹{totalPrice}
            </h6>
            <a
              href="#product_details"
              style={{
                fontSize: "12px",
                textDecoration: "none",
                color: "#ff3f6c",
                fontWeight: 700,
                cursor: "pointer",
              }}
            >
              VIEW DETAILS
            </a>
          </div>
          <Button
            className="d-flex justify-content-center align-items-center"
            variant="dark"
            style={{
              width: "60%",
              padding: "10px",
              background: "var(--them-color)",
              borderColor: "var(--them-color)",
            }}
            onClick={payNow}
          >
            PAY NOW
          </Button>
        </div>
      </Container>
    </>
  );
};

export default Payment;
