import { useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { load } from "@cashfreepayments/cashfree-js";
import { useRazorpay } from "react-razorpay";
import { createOrder } from "../../services/phonePeService";
const MultiPayment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("address"));
  const { Razorpay } = useRazorpay();
  const navigate = useNavigate();

  useEffect(() => {
    payNow();
  }, [searchParams]);

  // const multiPhonePePayment = async () => {
  //   const data = {
  //     name: searchParams.get("name"),
  //     mobileNumber: searchParams.get("mobileno"),
  //     amount: searchParams.get("amount"),
  //     merchantkey: process.env.REACT_APP_PHONEPE_MKEYS,
  //     merchantid: process.env.REACT_APP_PHONEPE_MIDS,
  //     domainname: searchParams.get("domainname"),
  //     noderedirectUrl: process.env.REACT_APP_PHONEPE_NODE_URL,
  //   };

  //   try {
  //     setLoading(true);
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/phonepay/create-order`,
  //       data
  //     );

  //     if (res.data?.data?.instrumentResponse?.redirectInfo?.url) {
  //       window.open(res.data.data.instrumentResponse.redirectInfo.url, "_self");
  //     }
  //   } catch (error) {
  //     console.error("Error in payment:", error);
  //   } finally {
  //     setTimeout(() => {
  //       setLoading(false);

  //       setTimeout(() => {
  //         window.location.href = `https://${searchParams.get(
  //           "domainname"
  //         )}/cart?paymentstatus=fail&name=${searchParams.get(
  //           "name"
  //         )}&mobileno=${searchParams.get("mobileno")}&amount=${searchParams.get(
  //           "amount"
  //         )}&domainname=${searchParams.get("domainname")}`;
  //       }, 100);
  //     }, 2000);
  //   }
  // };

  const multiPhonePePayment = async () => {
    const amount = searchParams.get("amount");
    const domainname = searchParams.get("domainname");
    try {
      setLoading(true);
      const checkoutUrl = await createOrder(amount, domainname);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Error in payment:", error);
    } finally {
      setLoading(false);
    }
  };

  // CashFree Integration
  let cashfree;
  const initializeSDK = async () => {
    try {
      cashfree = await load({ mode: "production" });
    } catch (error) {
      console.error("Error initializing Cashfree SDK:", error);
    }
  };

  // Ensure SDK is initialized before payment
  initializeSDK();
  const handleRazorpayPayment = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/razorpay/create-order`,
        {
          domainname: searchParams.get("domainname"),
          amount: searchParams.get("amount"),
          currency: "INR",
          razorPayId: process.env.REACT_APP_RAZORPAY_KEY_ID,
          razorPayKeySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
        }
      );
      const options = {
        key: data.razorPayId,
        amount: data.amount,
        currency: data.currency,
        name: window.location.hostname,
        description: "",
        image: process.env.REACT_APP_LOGO,
        order_id: data.id,
        handler: async (response) => {
          const trimmedOrderId = response.razorpay_order_id.replace(
            "order_",
            ""
          );
          // Verify the payment
          const { data: verifyResponse } = await axios.post(
            `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/razorpay/verify-payment`,
            {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              razorpay_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
            }
          );
          if (verifyResponse.success) {
            navigate(`/thankyou?txnId=${trimmedOrderId}`);
          } else {
            alert("Payment verification failed!");
          }
        },
        prefill: {
          name: window.location.hostname,
          email: `support@${window.location.hostname}.com`,
          contact: userDetails.mobile,
        },
        notes: {
          address: userDetails.address1,
        },
        theme: {
          color: process.env.REACT_APP_THEAM_COLOR,
        },
      };
      const razorpay = new Razorpay(options);
      razorpay.open();
    } catch (err) {
      console.log(err);
    }
  };

  const cashFreePayment = async () => {
    const customerphone = `+91${searchParams.get("mobileno")}`;
    const data = {
      customername: searchParams.get("name"),
      customerphone: customerphone,
      domainname: searchParams.get("domainname"),
      amount: searchParams.get("amount"),
      cashfreeappid: process.env.REACT_APP_CASHFREE_APP_ID,
      cashfreesecretkey: process.env.REACT_APP_CASHFREE_SECRET_KEY,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/cashfree/create-order`,
        data
      );
      if (!response.data.payment_session_id) {
        setLoading(false);
        console.error("Payment session ID not received");
        return;
      }
      setLoading(false);
      cashfree.checkout({
        paymentSessionId: response.data.payment_session_id,
        redirectTarget: "_self",
      });
      // Set timeout after opening Cashfree
      setTimeout(() => {
        setLoading(false);
        setTimeout(() => {
          window.location.href = `https://${searchParams.get(
            "domainname"
          )}/cart?paymentstatus=fail&name=${searchParams.get(
            "name"
          )}&mobileno=${searchParams.get("mobileno")}&amount=${searchParams.get(
            "amount"
          )}&domainname=${searchParams.get("domainname")}`;
        }, 100);
      }, 2000);
    } catch (error) {
      console.error("Error in payment:", error);
      setLoading(false);
    }
  };

  const IndianPayPayment = async () => {
    const data = {
      name: searchParams.get("name"),
      mobile: searchParams.get("mobileno"),
      amount: searchParams.get("amount"),
      domainname: searchParams.get("domainname"),
      email: "test@gmail.com",
      remark: "online",
      type: "indianpe",
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/pay/create-order`,
        data
      );
      setLoading(false);
      if (response.data?.payment_link) {
        window.open(response.data?.payment_link, "_self");
      }
    } catch (error) {
      console.error("Error in payment:", error);
      setLoading(false);
    }
  };

  const payNow = async () => {
    const paymentType = process.env.REACT_APP_PAYMENT_TYPE;
    if (paymentType === "razorpay") {
      handleRazorpayPayment();
    } else if (paymentType === "phonepe") {
      multiPhonePePayment();
    } else if (paymentType === "cashfree") {
      cashFreePayment();
    } else if (paymentType === "indianpay") {
      IndianPayPayment();
    } else {
      setLoading(true);
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Spinner />
          <div>Please wait</div>
        </div>
      )}
    </>
  );
};
export default MultiPayment;
